:root {
  --amplify-primary-color: #386ba7 !important;
}

:root::-webkit-scrollbar {
  display: none;
}

html,
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  // background-color: lightgray;
}
//必須*を赤字に
span.MuiFormLabel-asterisk {
  color: #D00;
}
//AppBarの高さ
.MuiToolbar-regular {
  min-height: 43px !important;
}

//タイトル付枠線
fieldset {
  border-radius: 8px;
  border-color: #595959;
  border-width: 1px;
  border-style: solid;
}

//アコーディオンの上下幅
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 0 !important;
}
.MuiAccordionSummary-content {
  margin: 0 0 !important;
}

//検索条件の余白
.MuiAccordionSummary-root {
  padding: 0px 0px !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 0px !important;
}

//Theme
.MuiAppBar-colorPrimary {
  background-color: #386ba7 !important;
}
//Theme - Primary
.MuiButton-containedPrimary {
  background-color: #386ba7 !important;
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #386ba7 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #386ba7 !important;
}
.MuiBadge-colorPrimary {
  background-color: #386ba7 !important;
  color: #fff !important;
}
//Theme - Secondary
.MuiButton-containedSecondary {
  background-color: #BDD7EE !important;
  color: #000 !important;
}
.MuiBadge-colorSecondary {
  background-color: #EBB304 !important;
  color: #000 !important;
}

.MuiGrid-item-pl0 {
  padding-left: 0px !important;
}

#DatePicker2MonthsDialog .MuiDateCalendar-root {
  width: 260px !important;
  height: 244px !important;
}
//右のカレンダーの高さ合わせ用
#DatePicker2MonthsDialog .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
  min-height: 150px;
}

//デフォルト文字色を黒に指定
.handsontable .htDimmed {
  color: #000 !important;
}
// 列幅短い場合に3点リーダーを表示
.htCore td {
  text-overflow: ellipsis;
}
.handsontable th, .handsontable td {
  white-space:nowrap !important;
  height: 18px !important;
  line-height: 17px !important;
}
.handsontable .htSeparator {
  height: 0px !important;
}
// 選択列ヘッダーの背景色
.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}
.handsontable td.area:before,
.handsontable td.current:before
{
  opacity: 0.1 !important;
  background: #2F75B5 !important;
}
// 選択行・列の背景色
.handsontable .currentRow, 
.handsontable .area, 
.handsontable .current {
  background-color: #DDEBF7;
}

.handsontable td.htSearchResult {
  background: #fcedd9 !important;
  color: #583707 !important;
}

.htDropdownMenu:not(.htGhostTable) {
  z-index: 1301 !important;
}
.htContextMenu:not(.htGhostTable) {
  z-index: 1301 !important;
}
.htCommentsContainer .htComments {
  z-index: 1301 !important;
}
.handsontable span.colHeader {
  pointer-events: none;
}
.recharts-tooltip-wrapper {
  z-index: 1301 !important;
}
.htFiltersConditionsMenu:not(.htGhostTable){
  z-index: 1302 !important;
}
//ソートマーカー
.handsontable span.sortMarker {
  background-color: blue;
  color: white;
  border: 1px solid black;
  padding: 2px;
}

td.has-event {
  background-color: #f5f5f5 !important;
}

td.is-disabled.has-event {
  background-color: #f2f8fd !important;
}

td.is-selection-disabled.has-event {
  background-color: #fcfcfc !important;
}

.has-event .pika-button {
  border: 2px solid skyblue;
  border-radius: 50% !important;
  height: 25px;
  background-color: #f5f5f5 !important;
  color: black !important;
  box-shadow: none !important;
  font-weight: normal !important;
}

.has-event .pika-button:hover,
.is-selected.has-event .pika-button:hover {
  background: #ff8000 !important;
}

.is-selected.has-event .pika-button {
  background: #33aaff !important;
}

.htContextMenu table tbody tr td.htCustomMenuRenderer.current {
  background: #f3f3f3;
  cursor: pointer;
}

/////////////////////////////////////////

// saleslist 左上ヘッダーの下線を消す
#SalesListTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// saleslist 列ヘッダーの背景色デフォルト
#SalesListTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// saleslist 列ヘッダー背景色
#SalesListTable.handsontable THEAD TH.saleslist_H {
  background: #595959;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_J {
  background: #5D44A2;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_JP {
  background: #A66BD3;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_K {
  background: #74274a;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_K2 {
  background: #a7386b;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_K3 {
  background: #bf427b;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_KP {
  background: #8e2f5b;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_KR {
  background: #9999FF;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_P {
  background: #698DCF;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_B {
  background: #70AD47;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_X {
  background: #486F2D;
  color: white;
}
#SalesListTable.handsontable THEAD TH.saleslist_XP {
  background: #578737;
  color: white;
}

// #SalesListTable.handsontable THEAD TH.saleslist_S,
// #SalesListTable.handsontable THEAD TH.saleslist_P,
// #SalesListTable.handsontable THEAD TH.saleslist_D,
// #SalesListTable.handsontable THEAD TH.saleslist_N,
// #SalesListTable.handsontable THEAD TH.saleslist_C,
// #SalesListTable.handsontable THEAD TH.saleslist_O,
// #SalesListTable.handsontable THEAD TH.saleslist_T,
// #SalesListTable.handsontable THEAD TH.saleslist_B,
// #SalesListTable.handsontable THEAD TH.saleslist_A,
// #SalesListTable.handsontable THEAD TH.saleslist_G,
// #SalesListTable.handsontable THEAD TH.saleslist_Z {
//   background: #386ba7;
//   color: white;
// }
// saleslist 行背景色
#SalesListTable.handsontable .saleslist_row_total {
  background: #CCFFFF;
}
#SalesListTable.handsontable .saleslist_row_grouping1 {
  background: #99FFFF;
}
#SalesListTable.handsontable .saleslist_row_grouping2 {
  background: #44FFFF;
}
//saleslist 行背景色 Lv2
#SalesListTable.handsontable .saleslist_row_lv2 {
  background: #E3F0DA;
}
//saleslist 行背景色 Lv3
#SalesListTable.handsontable .saleslist_row_lv3 {
  background: #C7E1B5;
}
//saleslist 行背景色 Lv4
#SalesListTable.handsontable .saleslist_row_lv4 {
  background: #A9D08E;
}
//saleslist 行背景色 Lv5
#SalesListTable.handsontable .saleslist_row_lv5 {
  background: #8DC169;
}
//saleslist 行背景色 Lv6
.handsontable .saleslist_row_lv6 {
  background: #71B244;
}
//saleslist 行背景色 Lv7
.handsontable .saleslist_row_lv7 {
  background: #55A31F;
}
//saleslist 行背景色 Lv8
.handsontable .saleslist_row_lv8 {
  background: #4DA30F;
}
//saleslist 行背景色 Lv2 選択時
#SalesListTable.handsontable .saleslist_row_lv2.currentRow, 
#SalesListTable.handsontable .saleslist_row_lv2.area, 
#SalesListTable.handsontable .saleslist_row_lv2.current {
  background: #DAE3F3;
}
//saleslist 行背景色 Lv3 選択時
#SalesListTable.handsontable .saleslist_row_lv3.currentRow,
#SalesListTable.handsontable .saleslist_row_lv3.area,
#SalesListTable.handsontable .saleslist_row_lv3.current {
  background: #B5C7E7;
}
//saleslist 行背景色 Lv4 選択時
#SalesListTable.handsontable .saleslist_row_lv4.currentRow, 
#SalesListTable.handsontable .saleslist_row_lv4.area,
#SalesListTable.handsontable .saleslist_row_lv4.current {
  background: #8EA9DB;
}
//saleslist 行背景色 Lv5 選択時
#SalesListTable.handsontable .saleslist_row_lv5.currentRow, 
#SalesListTable.handsontable .saleslist_row_lv5.area,
#SalesListTable.handsontable .saleslist_row_lv5.current {
  background: #698DCF;
}
//saleslist 行背景色 Lv6 選択時
#SalesListTable.handsontable .saleslist_row_lv6.currentRow, 
#SalesListTable.handsontable .saleslist_row_lv6.area,
#SalesListTable.handsontable .saleslist_row_lv6.current {
  background: #4471C3;  
}
//saleslist 行背景色 Lv7 選択時
#SalesListTable.handsontable .saleslist_row_lv7.currentRow,
#SalesListTable.handsontable .saleslist_row_lv7.area,
#SalesListTable.handsontable .saleslist_row_lv7.current {
  background: #1F55B7;
}
//saleslist 行背景色 Lv8 選択時
#SalesListTable.handsontable .saleslist_row_lv8.currentRow,
#SalesListTable.handsontable .saleslist_row_lv8.area,
#SalesListTable.handsontable .saleslist_row_lv8.current {
  background: #114DBD;
}

// saleslist セル文字色
#SalesListTable.handsontable .saleslist_cell_fg_gray {
  color: #BBB !important;
}
#SalesListTable.handsontable .saleslist_cell_fg_red {
  color: #F00 !important;
}
#SalesListTable.handsontable .saleslist_cell_fg_blue {
  color: #7491CD !important;
}
#SalesListTable.handsontable .saleslist_cell_rate_under_90 {
  background: #FFCCFF !important;
}
#SalesListTable.handsontable .saleslist_cell_rate_under_100 {
  background: #FFFF66 !important;
}
#SalesListTable.handsontable .saleslist_cell_rate_over_110 {
  background: #8DB4E2 !important;
}
#SalesListTable.handsontable .saleslist_cell_nebiki_rate_under_90 {
  background: #8DB4E2 !important;
}
#SalesListTable.handsontable .saleslist_cell_nebiki_rate_over_200 {
  background: #000 !important;
  color:white !important;
}
#SalesListTable.handsontable .saleslist_cell_nebiki_rate_over_110 {
  background: #FFCCFF !important;
}
#SalesListTable.handsontable .saleslist_cell_nebiki_rate_over_100 {
  background: #FFFF66 !important;
}
#SalesListTable.handsontable .saleslist_cell_pb_rate_over_10 {
  background: #8DB4E2 !important;
}
#SalesListTable.handsontable .saleslist_cell_pb_rate_over_07 {
  background: #B2DE84 !important;
}
#SalesListTable.handsontable .saleslist_cell_pb_rate_under_03 {
  background: #FFCCFF !important;
}

#SalesListTable.handsontable .saleslist_cell_keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#SalesListTable.handsontable .saleslist_cell_keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#SalesListTable.handsontable .saleslist_cell_keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#SalesListTable.handsontable .saleslist_cell_keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}
#SalesListTable.handsontable .saleslist_cell_keppin_rnk {
  background: #FFCCFF !important;
}

/////////////////////////////////////////
// saleslisttrans 左上ヘッダーのした線を消す
#SalesListTransTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// saleslisttrans 列ヘッダーの背景色デフォルト
#SalesListTransTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// saleslisttrans 列ヘッダー背景色
#SalesListTransTable.handsontable .saleslisttrans_H {
  background: #595959 !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_T {
  background: #386ba7 !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_P {
  background: #698DCF !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_B {
  background: #70AD47 !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_J {
  background: #5D44A2 !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_JP {
  background: #A66BD3 !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_K {
  background: #74274a !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_K2 {
  background: #a7386b !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_K3 {
  background: #bf427b !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_KP {
  background: #8e2f5b !important;
  color: white;
}

#SalesListTransTable.handsontable .saleslisttrans_PB {
  background: #ffa806 !important;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_X {
  background: #486F2D;
  color: white;
}
#SalesListTransTable.handsontable .saleslisttrans_XP {
  background: #578737;
  color: white;
}

// #SalesListTransTable.handsontable .saleslisttrans_S,
// #SalesListTransTable.handsontable .saleslisttrans_P,
// #SalesListTransTable.handsontable .saleslisttrans_D,
// #SalesListTransTable.handsontable .saleslisttrans_N,
// #SalesListTransTable.handsontable .saleslisttrans_C,
// #SalesListTransTable.handsontable .saleslisttrans_O,
// #SalesListTransTable.handsontable .saleslisttrans_T,
// #SalesListTransTable.handsontable .saleslisttrans_B,
// #SalesListTransTable.handsontable .saleslisttrans_A,
// #SalesListTransTable.handsontable .saleslisttrans_G,
// #SalesListTransTable.handsontable .saleslisttrans_Z {
//   background: #386ba7;
//   color: white;
// }
// saleslisttrans 行背景色
#SalesListTransTable.handsontable .saleslisttrans_row_total {
  background: #CCFFFF;
}
#SalesListTransTable.handsontable .saleslisttrans_row_grouping1 {
  background: #99FFFF;
}
#SalesListTransTable.handsontable .saleslisttrans_row_grouping2 {
  background: #44FFFF;
}
//saleslisttrans 行背景色 Lv2
#SalesListTransTable.handsontable .saleslisttrans_row_lv2 {
  background: #E3F0DA;
}
//saleslisttrans 行背景色 Lv3
#SalesListTransTable.handsontable .saleslisttrans_row_lv3 {
  background: #C7E1B5;
}
//saleslisttrans 行背景色 Lv4
#SalesListTransTable.handsontable .saleslisttrans_row_lv4 {
  background: #A9D08E;
}
//saleslisttrans 行背景色 Lv5
#SalesListTransTable.handsontable .saleslisttrans_row_lv5 {
  background: #8DC169;
}
//saleslisttrans 行背景色 Lv6
.handsontable .saleslisttrans_row_lv6 {
  background: #71B244;
}
//saleslisttrans 行背景色 Lv7
.handsontable .saleslisttrans_row_lv7 {
  background: #55A31F;
}
//saleslisttrans 行背景色 Lv8
.handsontable .saleslisttrans_row_lv8 {
  background: #4DA30F;
}
//saleslisttrans 行背景色 Lv2 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv2.currentRow, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv2.area, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv2.current {
  background: #DAE3F3;
}
//saleslisttrans 行背景色 Lv3 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv3.currentRow,
#SalesListTransTable.handsontable .saleslisttrans_row_lv3.area,
#SalesListTransTable.handsontable .saleslisttrans_row_lv3.current {
  background: #B5C7E7;
}
//saleslisttrans 行背景色 Lv4 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv4.currentRow, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv4.area,
#SalesListTransTable.handsontable .saleslisttrans_row_lv4.current {
  background: #8EA9DB;
}
//saleslisttrans 行背景色 Lv5 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv5.currentRow, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv5.area,
#SalesListTransTable.handsontable .saleslisttrans_row_lv5.current {
  background: #698DCF;
}
//saleslisttrans 行背景色 Lv6 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv6.currentRow, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv6.area,
#SalesListTransTable.handsontable .saleslisttrans_row_lv6.current {
  background: #4471C3;  
}
//saleslisttrans 行背景色 Lv7 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv7.currentRow, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv7.area,
#SalesListTransTable.handsontable .saleslisttrans_row_lv7.current {
  background: #1F55B7;
}
//saleslisttrans 行背景色 Lv8 選択時
#SalesListTransTable.handsontable .saleslisttrans_row_lv8.currentRow, 
#SalesListTransTable.handsontable .saleslisttrans_row_lv8.area,
#SalesListTransTable.handsontable .saleslisttrans_row_lv8.current {
  background: #114DBD;
}

// saleslisttrans セル文字色
#SalesListTransTable.handsontable .saleslisttrans_cell_border_bottom_none {
  border-bottom: none !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_border_bottom_dashed {
  border-bottom: 1px dashed #ccc !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_fg_gray {
  color: #BBB !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_fg_red {
  color: #F00 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_fg_blue {
  color: #7491CD !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_fg_none {
  color: transparent !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_rate_under_90 {
  background: #FFCCFF !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_rate_under_100 {
  background: #FFFF66 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_rate_over_110 {
  background: #8DB4E2 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_nebiki_rate_under_90 {
  background: #8DB4E2 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_nebiki_rate_over_200 {
  background: #000 !important;
  color:white !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_nebiki_rate_over_110 {
  background: #FFCCFF !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_nebiki_rate_over_100 {
  background: #FFFF66 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_pb_rate_over_10 {
  background: #8DB4E2 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_pb_rate_over_07 {
  background: #B2DE84 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_pb_rate_under_03 {
  background: #FFCCFF !important;
}

#SalesListTransTable.handsontable .saleslisttrans_cell_keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#SalesListTransTable.handsontable .saleslisttrans_cell_keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}

#SalesListTransTable.handsontable .saleslisttrans_cell_keppin_rnk {
  background: #FFCCFF !important;
}

// 選択列ヘッダーの背景色
#SalesListTransTable.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}

/////////////////////////////////////////

// itemlist 左上ヘッダーの下線を消す
#ItemListTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// itemlist 列ヘッダーの背景色デフォルト
#ItemListTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// itemlist 列ヘッダー背景色
#ItemListTable.handsontable THEAD TH.itemlist_H {
  background: #595959;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_J {
  background: #5D44A2;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_JP {
  background: #A66BD3;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_K {
  background: #a7386b;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_K2 {
  background: #cf698d;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_I {
  background: #595959;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_C {
  background: #000;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_X {
  background: #486F2D;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_XP {
  background: #578737;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_P {
  background: #698DCF;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_U {
  background: #FFC000;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_Z {
  background: #92D050;
  color: white;
}
#ItemListTable.handsontable THEAD TH.itemlist_T {
  background: #C0504D;
  color: white;
}

#ItemListTable.handsontable .itemlist_cell_fg_gray {
  color: #BBB !important;
}
#ItemListTable.handsontable .itemlist_cell_fg_red {
  color: #F00 !important;
}
#ItemListTable.handsontable .itemlist_cell_fg_blue {
  color: #7491CD !important;
}

// #ItemListTable.handsontable THEAD TH.itemlist_S,
// #ItemListTable.handsontable THEAD TH.itemlist_P,
// #ItemListTable.handsontable THEAD TH.itemlist_D,
// #ItemListTable.handsontable THEAD TH.itemlist_N,
// #ItemListTable.handsontable THEAD TH.itemlist_C,
// #ItemListTable.handsontable THEAD TH.itemlist_O,
// #ItemListTable.handsontable THEAD TH.itemlist_T,
// #ItemListTable.handsontable THEAD TH.itemlist_B,
// #ItemListTable.handsontable THEAD TH.itemlist_A,
// #ItemListTable.handsontable THEAD TH.itemlist_G,
// #ItemListTable.handsontable THEAD TH.itemlist_Z {
//   background: #386ba7;
//   color: white;
// }
// itemlist 行背景色
#ItemListTable.handsontable .itemlist_row_total {
  background: #CCFFFF;
}
#ItemListTable.handsontable .itemlist_row_grouping1 {
  background: #71B244;
}
#ItemListTable.handsontable .itemlist_row_grouping2 {
  background: #8DC169;
}
#ItemListTable.handsontable .itemlist_row_grouping3 {
  background: #A9D08E;
}
#ItemListTable.handsontable .itemlist_row_grouping4 {
  background: #C7E1B5;
}
#ItemListTable.handsontable .itemlist_row_grouping5 {
  background: #E3F0DA;
}
// itemlist セル文字色
#ItemListTable.handsontable .itemlist_cell_fg_red {
  color: #F00 !important;
}

#ItemListTable.handsontable .itemlist_cell_rate_under_90 {
  background: #FFCCFF !important;
}
#ItemListTable.handsontable .itemlist_cell_rate_under_100 {
  background: #FFFF66 !important;
}
#ItemListTable.handsontable .itemlist_cell_rate_over_110 {
  background: #8DB4E2 !important;
}
#ItemListTable.handsontable .itemlist_cell_nebiki_rate_under_90 {
  background: #8DB4E2 !important;
}
#ItemListTable.handsontable .itemlist_cell_nebiki_rate_over_200 {
  background: #000 !important;
  color:white !important;
}
#ItemListTable.handsontable .itemlist_cell_nebiki_rate_over_110 {
  background: #FFCCFF !important;
}
#ItemListTable.handsontable .itemlist_cell_nebiki_rate_over_100 {
  background: #FFFF66 !important;
}

#ItemListTable.handsontable .itemlist_cell_keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#ItemListTable.handsontable .itemlist_cell_keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#ItemListTable.handsontable .itemlist_cell_keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#ItemListTable.handsontable .itemlist_cell_keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}


/////////////////////////////////////////
//ItemZaikoTable
#ItemZaikoTable.handsontable THEAD TH.itemlist_H {
  background: #404040;
  color: white;
}
#ItemZaikoTable.handsontable THEAD TH.itemlist_I {
  background: #76933C;
  color: white;
}
#ItemZaikoTable.handsontable THEAD TH.itemlist_C {
  background: #386BA7;
  color: white;
}
#ItemZaikoTable.handsontable THEAD TH.itemlist_X {
  background: #60497A;
  color: white;
}
#ItemZaikoTable.handsontable .itemlist_row_total {
  background: #CCFFFF;
}
//ItemListGroup2Table
#ItemListGroup2Table.handsontable THEAD TH.itemlist_H {
  background: #404040;
  color: white;
}
#ItemListGroup2Table.handsontable THEAD TH.itemlist_I {
  background: #76933C;
  color: white;
}
#ItemListGroup2Table.handsontable THEAD TH.itemlist_C {
  background: #386BA7;
  color: white;
}
#ItemListGroup2Table.handsontable THEAD TH.itemlist_X {
  background: #60497A;
  color: white;
}
#ItemListGroup2Table.handsontable .itemlist_row_total {
  background: #CCFFFF;
}
#ItemListGroup2Table.handsontable .itemlist_row_group2 {
  background: #A9D08E;
}

//ItemTokuisakiTable
#ItemTokuisakiTable.handsontable THEAD TH.itemlist_H {
  background: #595959;
  color: white;
}
#ItemTokuisakiTable.handsontable THEAD TH.itemlist_C {
  background: #386BA7;
  color: white;
}
#ItemTokuisakiTable.handsontable .itemlist_row_total {
  background: #CCFFFF;
}
#ItemTokuisakiTable.handsontable .itemlist_row_group1 {
  background: #A9D08E;
}


#ItemTokuisakiTable.handsontable th div.ht_nestingButton.ht_nestingExpand::after {
  content: "＋";
}
#ItemTokuisakiTable.handsontable th div.ht_nestingButton.ht_nestingCollapse::after {
  content: "－";
}

#ItemListTable.handsontable .rankC,
#ItemListCutRankDetail.handsontable .rankC {
  background: #FFCC00;
}

#ItemListTable.handsontable .rankCα,
#ItemListTable.handsontable .rankCɤ,
#ItemListCutRankDetail.handsontable .rankCα,
#ItemListCutRankDetail.handsontable .rankCɤ {
  background: #FFCC66;
}

#ItemListTable.handsontable .rankZ,
#ItemListCutRankDetail.handsontable .rankZ {
  background: #FF7D7D;
}
#ItemListTable.handsontable .rankZX,
#ItemListCutRankDetail.handsontable .rankZX {
  background: #FF6666;
}
#ItemListTable.handsontable .itemlist_cell_bg_orange {
  background: orange;
}
#ItemListTable.handsontable .itemlist_cell_bg_skyblue {
  background: skyblue;
}

/////////////////////////////////////////
// itemlisttrans 左上ヘッダーのした線を消す
#ItemListTransTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// itemlisttrans 列ヘッダーの背景色デフォルト
#ItemListTransTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// itemlisttrans 列ヘッダー背景色
#ItemListTransTable.handsontable .itemlisttrans_H {
  background: #595959 !important;
  color: white;
}
#ItemListTransTable.handsontable THEAD TH.itemlisttrans_I {
  background: #595959;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_T {
  background: #386ba7 !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_X {
  background: #486F2D;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_XP {
  background: #578737;
  color: white;
}

#ItemListTransTable.handsontable .itemlisttrans_P {
  background: #698DCF !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_B {
  background: #70AD47 !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_J {
  background: #5D44A2 !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_JP {
  background: #A66BD3 !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_K {
  background: #a7386b !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_K2 {
  background: #cf698d !important;
  color: white;
}
#ItemListTransTable.handsontable .itemlisttrans_KP {
  background: #cf698d !important;
  color: white;
}

#ItemListTransTable.handsontable .itemlisttrans_PB {
  background: #ffa806 !important;
  color: white;
}
// #ItemListTransTable.handsontable .itemlisttrans_S,
// #ItemListTransTable.handsontable .itemlisttrans_P,
// #ItemListTransTable.handsontable .itemlisttrans_D,
// #ItemListTransTable.handsontable .itemlisttrans_N,
// #ItemListTransTable.handsontable .itemlisttrans_C,
// #ItemListTransTable.handsontable .itemlisttrans_O,
// #ItemListTransTable.handsontable .itemlisttrans_T,
// #ItemListTransTable.handsontable .itemlisttrans_B,
// #ItemListTransTable.handsontable .itemlisttrans_A,
// #ItemListTransTable.handsontable .itemlisttrans_G,
// #ItemListTransTable.handsontable .itemlisttrans_Z {
//   background: #386ba7;
//   color: white;
// }
// itemlisttrans 行背景色
#ItemListTransTable.handsontable .itemlisttrans_row_total {
  background: #CCFFFF;
}
//itemlisttrans 行背景色 Lv2
#ItemListTransTable.handsontable .itemlisttrans_row_lv2 {
  background: #E3F0DA;
}
//itemlisttrans 行背景色 Lv3
#ItemListTransTable.handsontable .itemlisttrans_row_lv3 {
  background: #C7E1B5;
}
//itemlisttrans 行背景色 Lv4
#ItemListTransTable.handsontable .itemlisttrans_row_lv4 {
  background: #A9D08E;
}
//itemlisttrans 行背景色 Lv5
#ItemListTransTable.handsontable .itemlisttrans_row_lv5 {
  background: #8DC169;
}
//itemlisttrans 行背景色 Lv6
.handsontable .itemlisttrans_row_lv6 {
  background: #71B244;
}
//itemlisttrans 行背景色 Lv7
.handsontable .itemlisttrans_row_lv7 {
  background: #55A31F;
}
//itemlisttrans 行背景色 Lv8
.handsontable .itemlisttrans_row_lv8 {
  background: #4DA30F;
}
//itemlisttrans 行背景色 Lv2 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv2.currentRow, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv2.area, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv2.current {
  background: #DAE3F3;
}
//itemlisttrans 行背景色 Lv3 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv3.currentRow,
#ItemListTransTable.handsontable .itemlisttrans_row_lv3.area,
#ItemListTransTable.handsontable .itemlisttrans_row_lv3.current {
  background: #B5C7E7;
}
//itemlisttrans 行背景色 Lv4 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv4.currentRow, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv4.area,
#ItemListTransTable.handsontable .itemlisttrans_row_lv4.current {
  background: #8EA9DB;
}
//itemlisttrans 行背景色 Lv5 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv5.currentRow, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv5.area,
#ItemListTransTable.handsontable .itemlisttrans_row_lv5.current {
  background: #698DCF;
}
//itemlisttrans 行背景色 Lv6 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv6.currentRow, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv6.area,
#ItemListTransTable.handsontable .itemlisttrans_row_lv6.current {
  background: #4471C3;  
}
//itemlisttrans 行背景色 Lv7 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv7.currentRow, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv7.area,
#ItemListTransTable.handsontable .itemlisttrans_row_lv7.current {
  background: #1F55B7;
}
//itemlisttrans 行背景色 Lv8 選択時
#ItemListTransTable.handsontable .itemlisttrans_row_lv8.currentRow, 
#ItemListTransTable.handsontable .itemlisttrans_row_lv8.area,
#ItemListTransTable.handsontable .itemlisttrans_row_lv8.current {
  background: #114DBD;
}

// itemlisttrans セル文字色
#ItemListTransTable.handsontable .itemlisttrans_cell_border_bottom_none {
  border-bottom: none !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_border_bottom_dashed {
  border-bottom: 1px dashed #ccc !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_fg_gray {
  color: #BBB !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_fg_red {
  color: #F00 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_fg_blue {
  color: #7491CD !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_fg_none {
  color: transparent !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_rate_under_90 {
  background: #FFCCFF !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_rate_under_100 {
  background: #FFFF66 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_rate_over_110 {
  background: #8DB4E2 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_nebiki_rate_under_90 {
  background: #8DB4E2 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_nebiki_rate_over_200 {
  background: #000 !important;
  color:white !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_nebiki_rate_over_110 {
  background: #FFCCFF !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_nebiki_rate_over_100 {
  background: #FFFF66 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_pb_rate_over_10 {
  background: #8DB4E2 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_pb_rate_over_07 {
  background: #B2DE84 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_pb_rate_under_03 {
  background: #FFCCFF !important;
}

#ItemListTransTable.handsontable .itemlisttrans_cell_keppin_rate_over_5 {
  background: #000 !important;
  color:white !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_keppin_rate_over_3 {
  background: #FFCCFF !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_keppin_rate_over_1 {
  background: #FFFF66 !important;
}
#ItemListTransTable.handsontable .itemlisttrans_cell_keppin_rate_diff_over_1 {
  background: #FFCCFF !important;
}

// 選択列ヘッダーの背景色
#ItemListTransTable.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}


/////////////////////////////////////////

// tokubaiinput 左上ヘッダーの下線を消す
// #TokubaiInputTable.handsontable thead tr th:first-child {
//   border-bottom: none
// }
// tokubaiinput 列ヘッダーの背景色デフォルト
#TokubaiInputTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}
// tokubaiinput 列ヘッダー背景色
#TokubaiInputTable.handsontable THEAD TH.tokubaiinput_I {
  background: #EBB304;
  color: black;
}
#TokubaiInputTable.handsontable THEAD TH.tokubaiinput_S {
  background: #386BA7;
  color: white;
}
#TokubaiInputTable.handsontable THEAD TH.tokubaiinput_Z {
  background: #548235;
  color: white;
}
#TokubaiInputTable.handsontable THEAD TH.tokubaiinput_T {
  background: #C0504D;
  color: white;
}
//tokubaiinput 入力可能
#TokubaiInputTable.handsontable .tokubaiinput_cell_editable {
  background: #FFFFCC;
}
//tokubaiinput 入力可能 選択時
#TokubaiInputTable.handsontable .tokubaiinput_cell_editable.currentRow, 
#TokubaiInputTable.handsontable .tokubaiinput_cell_editable.area,
#TokubaiInputTable.handsontable .tokubaiinput_cell_editable.current {
  background: #F6F2B3;
}
//tokubaiinput 入力不可
#TokubaiInputTable.handsontable .tokubaiinput_cell_uneditable {
  background: #E0E0E0;
}
//tokubaiinput 入力不可 選択時
#TokubaiInputTable.handsontable .tokubaiinput_cell_uneditable.currentRow, 
#TokubaiInputTable.handsontable .tokubaiinput_cell_uneditable.area,
#TokubaiInputTable.handsontable .tokubaiinput_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaiinput 入力エラー
#TokubaiInputTable.handsontable .tokubaiinput_cell_error {
  background: #FFCCCC;
}
//tokubaiinput 警告
#TokubaiInputTable.handsontable .tokubaiinput_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 編集済
#TokubaiInputTable.handsontable .tokubaiinput_cell_edited {
  background: #A9D08E;
}
//tokubaiinput 入力エラー 選択時
#TokubaiInputTable.handsontable .tokubaiinput_cell_error.currentRow, 
#TokubaiInputTable.handsontable .tokubaiinput_cell_error.area,
#TokubaiInputTable.handsontable .tokubaiinput_cell_error.current {
  background: #F6BFB3;
}
//tokubaiinput 警告 選択時
#TokubaiInputTable.handsontable .tokubaiinput_cell_warn.currentRow, 
#TokubaiInputTable.handsontable .tokubaiinput_cell_warn.area,
#TokubaiInputTable.handsontable .tokubaiinput_cell_warn.current {
  background: #df9f29;
}
//tokubaiinput 土曜日
#TokubaiInputTable.handsontable .tokubaiinput_cell_saturday {
  color: blue;
}
//tokubaiinput 休日（日・祝）
#TokubaiInputTable.handsontable .tokubaiinput_cell_holiday {
  color: red;
}
#TokubaiInputTable.handsontable .tokubaiinput_cell_fg_red {
  color: #F00 !important;
}

//TokubaiInputDuplicateDialog
#TokubaiInputDuplicateDialog.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}

// 列ヘッダー背景色
#TokubaiInputDuplicateDialog.handsontable THEAD TH.duplicatedialog_I {
  background: #EBB304;
  color: black;
}
#TokubaiInputDuplicateDialog.handsontable THEAD TH.duplicatedialog_S {
  background: #386BA7;
  color: white;
}
#TokubaiInputDuplicateDialog.handsontable THEAD TH.duplicatedialog_M {
  background: #A9D08E;
  color: black;
}

#TokubaiInputHorizontalTable.handsontable .header_noBorder_no { //No.
  background: #333 !important;
  color: white;
  // border-bottom: 1px #333 solid;
  border-bottom: none;
}
#TokubaiInputHorizontalTable.handsontable .header_no { //No.
  background: #333 !important;
  color: white;
}

#TokubaiInputHorizontalTable.handsontable .cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}

#TokubaiInputHorizontalTable.handsontable .tokubaiinput_I {
  background: #EBB304;
  color: black;
}
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_G {
  background: #595959;
  color: white;
}
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_S {
  background: #386BA7;
  color: white;
}
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_B {
  background: #D9E1F2;
  color: black;
}
//tokubaiinput 入力可能
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_editable {
  background: #FFFFCC;
}
//tokubaiinput 入力可能 選択時
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_editable.currentRow, 
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_editable.area,
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_editable.current {
  background: #F6F2B3;
}
//tokubaiinput 入力不可
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_uneditable {
  background: #E0E0E0;
}
//tokubaiinput 入力不可 選択時
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_uneditable.currentRow, 
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_uneditable.area,
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_uneditable.current {
  background: #D7D3C7;
}

//tokubaiinput 入力エラー
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_error {
  background: #FFCCCC;
}
//tokubaiinput 入力エラー 選択時
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_error.currentRow,
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_error.area,
#TokubaiInputHorizontalTable.handsontable .tokubaiinput_cell_error.current {
  background: #F6BFB3;
}
/////////////////////////////////////////

// tokubaimanage 左上ヘッダーの下線を消す
// #TokubaiManageTable.handsontable thead tr th:first-child {
//   border-bottom: none
// }
// tokubaimanage 列ヘッダーの背景色デフォルト
#TokubaiManageTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}
// tokubaimanage 列ヘッダー背景色
#TokubaiManageTable.handsontable THEAD TH.tokubaimanage_I {
  background: #EBB304;
  color: black;
}
#TokubaiManageTable.handsontable THEAD TH.tokubaimanage_S {
  background: #386BA7;
  color: white;
}
#TokubaiManageTable.handsontable THEAD TH.tokubaimanage_Z {
  background: #548235;
  color: white;
}
#TokubaiManageTable.handsontable THEAD TH.tokubaimanage_M {
  background: #A9D08E;
  color: black;
}
#TokubaiManageTable.handsontable THEAD TH.tokubaimanage_A {
  background: #7030A0;
  color: white;
}
#TokubaiManageTable.handsontable THEAD TH.tokubaimanage_T {
  background: #C0504D;
  color: white;
}
//tokubaimanage 入力可能
#TokubaiManageTable.handsontable .tokubaimanage_cell_editable {
  background: #FFFFCC;
}
//tokubaimanage 入力可能 選択時
#TokubaiManageTable.handsontable .tokubaimanage_cell_editable.currentRow, 
#TokubaiManageTable.handsontable .tokubaimanage_cell_editable.area,
#TokubaiManageTable.handsontable .tokubaimanage_cell_editable.current {
  background: #F6F2B3;
}
//tokubaimanage 入力不可
#TokubaiManageTable.handsontable .tokubaimanage_cell_uneditable {
  background: #E0E0E0;
}
//tokubaimanage 入力不可 選択時
#TokubaiManageTable.handsontable .tokubaimanage_cell_uneditable.currentRow, 
#TokubaiManageTable.handsontable .tokubaimanage_cell_uneditable.area,
#TokubaiManageTable.handsontable .tokubaimanage_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaimanage 入力エラー
#TokubaiManageTable.handsontable .tokubaimanage_cell_error {
  background: #FFCCCC;
}
//tokubaimanage 入力警告
#TokubaiManageTable.handsontable .tokubaimanage_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 編集済
#TokubaiManageTable.handsontable .tokubaimanage_cell_edited {
  background: #A9D08E;
}
//tokubaimanage 入力エラー 選択時
#TokubaiManageTable.handsontable .tokubaimanage_cell_error.currentRow, 
#TokubaiManageTable.handsontable .tokubaimanage_cell_error.area,
#TokubaiManageTable.handsontable .tokubaimanage_cell_error.current {
  background: #F6BFB3;
}
//tokubaimanage 入力警告 選択時
#TokubaiManageTable.handsontable .tokubaimanage_cell_warn.currentRow, 
#TokubaiManageTable.handsontable .tokubaimanage_cell_warn.area,
#TokubaiManageTable.handsontable .tokubaimanage_cell_warn.current {
  background: #df9f29;
}
//tokubaimanage 土曜日
#TokubaiManageTable.handsontable .tokubaimanage_cell_saturday {
  color: blue !important;
}
//tokubaimanage 休日（日・祝）
#TokubaiManageTable.handsontable .tokubaimanage_cell_holiday {
  color: red !important;
}

//受信管理・未受信用
#TokubaiManageTable.handsontable .tokubaimanage_cell_purple {
  background: purple !important;
  color: white !important;
}
//受信管理・当日受信用
#TokubaiManageTable.handsontable .tokubaimanage_cell_red {
  background: red !important;
  color: white !important;
}

#TokubaiManageTable.handsontable .tokubaimanage_row_total {
  background: #C3FFC3;
}

#TokubaiManageTable.handsontable .tokubaimanage_cell_fg_red {
  color: #F00 !important;
}

.tokubaiManageZaikoTable {
  border-collapse: collapse;
}
.tokubaiManageZaikoTable th{
  border-top: 1px silver solid;
  border-left: 1px silver solid;
  border-right: 1px silver solid;
  border-bottom: 1px silver solid;
}
.tokubaiManageZaikoTable th.tokubaiManageZaikoTable_TH_Z{
  background: #548235;
  color: white;
}
.tokubaiManageZaikoTable th.tokubaiManageZaikoTable_TH_O{
  background: #595959;
  color: white;
}
.tokubaiManageZaikoTable td{
  border-top: 1px silver solid;
  border-left: 1px silver solid;
  border-right: 1px silver solid;
  border-bottom: 1px silver solid;
}
.tokubaiManageZaikoTable td.borderless{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

//tokubaimanage 入力可能
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_editable {
  background: #FFFFCC;
}
//tokubaimanage 入力可能 選択時
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_editable.currentRow, 
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_editable.area,
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_editable.current {
  background: #F6F2B3;
}
//tokubaimanage 入力不可
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_uneditable {
  background: #E0E0E0;
}
//tokubaimanage 入力不可 選択時
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_uneditable.currentRow, 
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_uneditable.area,
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaimanage 入力エラー
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_error {
  background: #FFCCCC;
}
//tokubaimanage 入力エラー 選択時
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_error.currentRow, 
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_error.area,
#TokubaiManageFurikaeListTable.handsontable .tokubaimanage_cell_error.current {
  background: #F6BFB3;
}
// tokubaimanage 列ヘッダーの背景色デフォルト
#TokubaiManageFurikaeListTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}
// tokubaimanage 列ヘッダー背景色
#TokubaiManageFurikaeListTable.handsontable THEAD TH.tokubaimanage_Z {
  background: #548235;
  color: white;
}
#TokubaiManageFurikaeListTable.handsontable THEAD TH.tokubaimanage_I {
  background: #EBB304;
  color: black;
}

// tokubaimanage 列ヘッダーの背景色デフォルト
#TokubaiManageZanFurikaeListSakiTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}

//tokubaimanage 入力可能
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_editable {
  background: #FFFFCC;
}
//tokubaimanage 入力可能 選択時
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_editable.currentRow, 
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_editable.area,
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_editable.current {
  background: #F6F2B3;
}
//tokubaimanage 入力不可
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_uneditable {
  background: #E0E0E0;
}
//tokubaimanage 入力不可 選択時
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_uneditable.currentRow, 
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_uneditable.area,
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaimanage 入力エラー
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_error {
  background: #FFCCCC;
}
//tokubaimanage 入力エラー 選択時
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_error.currentRow, 
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_error.area,
#TokubaiManageZanFurikaeListMotoTable.handsontable .tokubaimanage_cell_error.current {
  background: #F6BFB3;
}
// tokubaimanage 列ヘッダーの背景色デフォルト
#TokubaiManageZanFurikaeListMotoTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}


#TokubaiManageChangeValueTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}
// tokubaimanage 列ヘッダー背景色
#TokubaiManageChangeValueTable.handsontable THEAD TH.tokubaimanage_I {
  background: #EBB304;
  color: black;
}
#TokubaiManageChangeValueTable.handsontable THEAD TH.tokubaimanage_S {
  background: #386BA7;
  color: white;
}
#TokubaiManageChangeValueTable.handsontable THEAD TH.tokubaimanage_M {
  background: #A9D08E;
  color: black;
}
#TokubaiManageChangeValueTable.handsontable THEAD TH.tokubaimanage_R {
  background: #ff6;
  color: black;
}

//tokubaimanage 編集済
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_edited {
  background: #A9D08E;
}

//tokubaimanage 入力可能
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_editable {
  background: #FFFFCC;
}
//tokubaimanage 入力可能 選択時
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_editable.currentRow, 
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_editable.area,
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_editable.current {
  background: #F6F2B3;
}
//tokubaimanage 入力不可
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_uneditable {
  background: #E0E0E0;
}
//tokubaimanage 入力不可 選択時
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_uneditable.currentRow, 
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_uneditable.area,
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaimanage 入力エラー
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_error {
  background: #FFCCCC;
}
//tokubaimanage 入力エラー 選択時
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_error.currentRow, 
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_error.area,
#TokubaiManageChangeValueTable.handsontable .tokubaimanage_cell_error.current {
  background: #F6BFB3;
}

#TokubaiManageChangeOrderTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}

// tokubaimanage 列ヘッダー背景色
#TokubaiManageChangeOrderTable.handsontable THEAD TH.tokubaimanage_I {
  background: #EBB304;
  color: black;
}
#TokubaiManageChangeOrderTable.handsontable THEAD TH.tokubaimanage_A {
  background: #7030A0;
  color: white;
}
#TokubaiManageChangeOrderTable.handsontable THEAD TH.tokubaimanage_M {
  background: #A9D08E;
  color: black;
}
#TokubaiManageChangeOrderTable.handsontable THEAD TH.tokubaimanage_R {
  background: #ff6;
  color: black;
}

//tokubaimanage 編集済
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_edited {
  background: #A9D08E;
}

//tokubaimanage 入力可能
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_editable {
  background: #FFFFCC;
}
//tokubaimanage 入力可能 選択時
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_editable.currentRow, 
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_editable.area,
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_editable.current {
  background: #F6F2B3;
}
//tokubaimanage 入力不可
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_uneditable {
  background: #E0E0E0;
}
//tokubaimanage 入力不可 選択時
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_uneditable.currentRow, 
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_uneditable.area,
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaimanage 入力エラー
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_error {
  background: #FFCCCC;
}
//tokubaimanage 入力エラー 選択時
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_error.currentRow, 
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_error.area,
#TokubaiManageChangeOrderTable.handsontable .tokubaimanage_cell_error.current {
  background: #F6BFB3;
}

#TokubaiManageStatusChangeTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}

// tokubaimanage 列ヘッダー背景色
#TokubaiManageStatusChangeTable.handsontable THEAD TH.tokubaimanage_I {
  background: #EBB304;
  color: black;
}
#TokubaiManageStatusChangeTable.handsontable THEAD TH.tokubaimanage_Z {
  background: #548235;
  color: white;
}
#TokubaiManageStatusChangeTable.handsontable THEAD TH.tokubaimanage_M {
  background: #A9D08E;
  color: black;
}
#TokubaiManageStatusChangeTable.handsontable THEAD TH.tokubaimanage_R {
  background: #ff6;
  color: black;
}

//tokubaimanage 編集済
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_edited {
  background: #A9D08E;
}

//tokubaimanage 入力可能
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_editable {
  background: #FFFFCC;
}
//tokubaimanage 入力可能 選択時
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_editable.currentRow, 
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_editable.area,
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_editable.current {
  background: #F6F2B3;
}
//tokubaimanage 入力不可
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_uneditable {
  background: #E0E0E0;
}
//tokubaimanage 入力不可 選択時
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_uneditable.currentRow, 
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_uneditable.area,
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaimanage 入力エラー
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_error {
  background: #FFCCCC;
}
//tokubaimanage 入力エラー 選択時
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_error.currentRow, 
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_error.area,
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_error.current {
  background: #F6BFB3;
}
//tokubaimanage 入力警告
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 入力警告 選択時
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_warn.currentRow, 
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_warn.area,
#TokubaiManageStatusChangeTable.handsontable .tokubaimanage_cell_warn.current {
  background: #df9f29;
}

/////////////////////////////////////////

// tokubaiprogress 列ヘッダーの背景色デフォルト
#TokubaiProgressTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}
// tokubaiprogress 列ヘッダー背景色
#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_I {
  background: #EBB304;
  color: black;
}
#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_S {
  background: #386BA7;
  color: white;
}

#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_GN {
  background: #A9D08E;
  color: black;
}

#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_GY {
  background: #595959;
  color: white;
}

#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_P {
  background: #7030A0;
  color: white;
}
#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_Z {
  background: #548235;
  color: white;
}
#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_M {
  background: #A9D08E;
  color: black;
}
#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_A {
  background: #6600FF;
  color: white;
}
#TokubaiProgressTable.handsontable THEAD TH.tokubaiprogress_T {
  background: #C0504D;
  color: white;
}
//tokubaiprogress 入力可能
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_editable {
  background: #FFFFCC;
}
//tokubaiprogress 入力可能 選択時
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_editable.currentRow, 
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_editable.area,
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_editable.current {
  background: #F6F2B3;
}
//tokubaiprogress 入力不可
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_uneditable {
  background: #E0E0E0;
}
//tokubaiprogress 入力不可 選択時
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_uneditable.currentRow, 
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_uneditable.area,
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaiprogress 入力エラー
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_error {
  background: #FFCCCC;
}
//tokubaiprogress 入力警告
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_warn {
  background: #FFB62F;
}
//tokubaiprogress 編集済
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_edited {
  background: #A9D08E;
}
//tokubaiprogress 入力エラー 選択時
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_error.currentRow, 
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_error.area,
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_error.current {
  background: #F6BFB3;
}
//tokubaiprogress 入力警告 選択時
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_warn.currentRow, 
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_warn.area,
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_warn.current {
  background: #df9f29;
}
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_bold { //太字
  font-weight: bold;
}

//受信管理・未受信用
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_purple {
  background: purple !important;
  color: white !important;
}
//受信管理・当日受信用
#TokubaiProgressTable.handsontable .tokubaiprogress_cell_red {
  background: red !important;
  color: white !important;
}

#TokubaiProgressTable.handsontable .tokubaiprogress_cell_fg_red {
  color: #F00 !important;
}

.tokubaiProgressZaikoTable {
  border-collapse: collapse;
}
.tokubaiProgressZaikoTable th{
  border-top: 1px silver solid;
  border-left: 1px silver solid;
  border-right: 1px silver solid;
  border-bottom: 1px silver solid;
}
.tokubaiProgressZaikoTable th.tokubaiProgressZaikoTable_TH_Z{
  background: #548235;
  color: white;
}
.tokubaiProgressZaikoTable th.tokubaiProgressZaikoTable_TH_O{
  background: #595959;
  color: white;
}
.tokubaiProgressZaikoTable td{
  border-top: 1px silver solid;
  border-left: 1px silver solid;
  border-right: 1px silver solid;
  border-bottom: 1px silver solid;
}
.tokubaiProgressZaikoTable td.borderless{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

/////////////////////////////////////////

// tokubaisetting 列ヘッダーの背景色デフォルト
#TokubaiSettingOrderUserTable.handsontable thead th,
#TokubaiSettingMakerTable.handsontable thead th,
#TokubaiSettingGroup1NameTable.handsontable thead th,
#TokubaiSettingTokuisakiTable.handsontable thead th {
  background: #595959;
  color: white;
  vertical-align: bottom;
}
//tokubaisetting 入力可能
.handsontable .tokubaisetting_cell_editable {
  background: #FFFFCC;
}
//tokubaisetting 入力可能 選択時
.handsontable .tokubaisetting_cell_editable.currentRow, 
.handsontable .tokubaisetting_cell_editable.area,
.handsontable .tokubaisetting_cell_editable.current {
  background: #F6F2B3;
}
//tokubaisetting 入力不可
.handsontable .tokubaisetting_cell_uneditable {
  background: #E0E0E0;
}
//tokubaisetting 入力不可 選択時
.handsontable .tokubaisetting_cell_uneditable.currentRow, 
.handsontable .tokubaisetting_cell_uneditable.area,
.handsontable .tokubaisetting_cell_uneditable.current {
  background: #D7D3C7;
}
//tokubaisetting 削除
.handsontable .tokubaisetting_cell_deleted {
  text-decoration: line-through;
}
//tokubaisetting 入力エラー
.handsontable .tokubaisetting_cell_error {
  background: #FFCCCC;
}
//tokubaisetting 編集済
.handsontable .tokubaisetting_cell_edited {
  background: #A9D08E;
}
//tokubaisetting 入力エラー 選択時
.handsontable .tokubaisetting_cell_error.currentRow, 
.handsontable .tokubaisetting_cell_error.area,
.handsontable .tokubaisetting_cell_error.current {
  background: #F6BFB3;
}

/////////////////////////////////////////

/////////////////////////////////////////
// zaikosummary 左上ヘッダーのした線を消す
#ZaikoSummaryTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// zaikosummary 列ヘッダーの背景色デフォルト
#ZaikoSummaryTable.handsontable thead th {
  background: #339966;
  color: white;
}
// zaikosummary 右側線はデフォルト破線する
#ZaikoSummaryTable.handsontable th, 
#ZaikoSummaryTable.handsontable td {
  border-right: 1px dashed #ccc;
}
// zaikosummary 列ヘッダー背景色
#ZaikoSummaryTable.handsontable .zaikosummary_H {
  background: #606060 !important;
  color: white;
}
// zaikosummary 列ヘッダー 下線なし
#ZaikoSummaryTable.handsontable .zaikosummary_H_noBorder {
  background: #606060 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikosummary 列ヘッダー背景色(出荷)
#ZaikoSummaryTable.handsontable .zaikosummary_H_N {
  background: #386ba7 !important;
  color: white;
}
// zaikosummary 列ヘッダー 下線なし(出荷)
#ZaikoSummaryTable.handsontable .zaikosummary_H_N_noBorder {
  background: #386ba7 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikosummary 列ヘッダー背景色(在庫)
#ZaikoSummaryTable.handsontable .zaikosummary_H_Z {
  background: #339966 !important;
  color: white;
}
// zaikosummary 列ヘッダー 下線なし(在庫)
#ZaikoSummaryTable.handsontable .zaikosummary_H_Z_noBorder {
  background: #339966 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikosummary 列ヘッダー背景色(1/3超過)
#ZaikoSummaryTable.handsontable .zaikosummary_H_O3 {
  background: #FBCFD0 !important;
  color: black;
}
// zaikosummary 列ヘッダー背景色(1/2超過)
#ZaikoSummaryTable.handsontable .zaikosummary_H_O2 {
  background: #FF4040 !important;
  color: white;
}
// zaikosummary 列ヘッダー背景色(賞味超過)
#ZaikoSummaryTable.handsontable .zaikosummary_H_O {
  background: #7F649F !important;
  color: white;
}
#ZaikoSummaryTable.handsontable .zaikosummary_H_group { //グループ行
  background: #D6DCE4 !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_H_total { //合計行
  background: #E1EBE5 !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_H_info { //明細行
  background: #F2F2F2;
}
#ZaikoSummaryTable.handsontable .zaikosummary_RH {
}
#ZaikoSummaryTable.handsontable .zaikosummary_RH_total {
  background: #D6E4DC;
}
#ZaikoSummaryTable.handsontable .zaikosummary_T {
  background: #C0504D;
  color: white;
}
// zaikosummary セル文字色
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bold { //太字
  font-weight: bold;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_top_bold {
  border-top: 0.5px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_right_bold {
  border-right: 0.5px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_right_bold_white {
  border-right: 0.5px white solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_right_bold2 {
  border-right: 1.5px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_left_bold {
  border-left: 0.5px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_left_bold_white {
  border-left: 0.5px white solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_border_left_bold2 {
  border-left: 1.5px black solid !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_fg_gray {
  color: #BBB !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_fg_red {
  color: #F00 !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_fg_blue {
  color: #7491CD !important;
}
#ZaikoSummaryTable.handsontable .zaikosummary_cell_fg_none {
  color: transparent !important;
}
//99/99/99
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_invalid {
  color: #808080 !important;
  background: #F0F0F0 !important;
}
//～101日
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_101 {
  color: #FFF !important;
  background: #FF4040 !important;
}
//100～31日
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_031 {
  background: #FFB0B0 !important;
}
//30～11日
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_011 {
  background: #FFE0E0 !important;
}
//9999999
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_999 {
  color: #808080 !important;
  background: #D9D9D9 !important;
}
//(1/3超過)
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_over3 {
  background: #FBCFD0 !important;
  color: #000 !important;
}
//(1/2超過)
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_over2 {
  background: #FF4040 !important;
  color: #FFF !important;
}
//(賞味超過)
#ZaikoSummaryTable.handsontable .zaikosummary_cell_bg_over {
  background: #7F649F !important;
  color: #FFF !important;
}


//zaikosummary 選択時
#ZaikoSummaryTable.handsontable .currentRow, 
#ZaikoSummaryTable.handsontable .area,
#ZaikoSummaryTable.handsontable .current {
  background: #DDEBF7;
}

//zaikosummary 入力可能
#ZaikoSummaryTable.handsontable .zaikosummary_cell_editable {
  background: #FFFFCC;
}
//zaikosummary 入力可能 選択時
#ZaikoSummaryTable.handsontable .zaikosummary_cell_editable.currentRow, 
#ZaikoSummaryTable.handsontable .zaikosummary_cell_editable.area,
#ZaikoSummaryTable.handsontable .zaikosummary_cell_editable.current {
  background: #F6F2B3;
}
//zaikosummary 入力不可
#ZaikoSummaryTable.handsontable .zaikosummary_cell_uneditable {
  background: #E8E8E8;
}
//zaikosummary 入力不可 選択時
#ZaikoSummaryTable.handsontable .zaikosummary_cell_uneditable.currentRow, 
#ZaikoSummaryTable.handsontable .zaikosummary_cell_uneditable.area,
#ZaikoSummaryTable.handsontable .zaikosummary_cell_uneditable.current {
  background: #D7D3C7;
}
//zaikosummary 入力エラー
#ZaikoSummaryTable.handsontable .zaikosummary_cell_error {
  background: #FFCCCC;
}
//zaikosummary 警告
#ZaikoSummaryTable.handsontable .zaikosummary_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 編集済
#ZaikoSummaryTable.handsontable .zaikosummary_cell_edited {
  background: #A9D08E;
}
//zaikosummary 入力エラー 選択時
#ZaikoSummaryTable.handsontable .zaikosummary_cell_error.currentRow, 
#ZaikoSummaryTable.handsontable .zaikosummary_cell_error.area,
#ZaikoSummaryTable.handsontable .zaikosummary_cell_error.current {
  background: #F6BFB3;
}
//zaikosummary 警告 選択時
#ZaikoSummaryTable.handsontable .zaikosummary_cell_warn.currentRow, 
#ZaikoSummaryTable.handsontable .zaikosummary_cell_warn.area,
#ZaikoSummaryTable.handsontable .zaikosummary_cell_warn.current {
  background: #df9f29;
}
// 選択列ヘッダーの背景色
#ZaikoSummaryTable.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}



/////////////////////////////////////////
// zaikoreal 左上ヘッダーのした線を消す
#ZaikoRealTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// zaikoreal 列ヘッダーの背景色デフォルト
#ZaikoRealTable.handsontable thead th {
  background: #339966;
  color: white;
}
// zaikoreal 右側線はデフォルト破線する
#ZaikoRealTable.handsontable th, 
#ZaikoRealTable.handsontable td {
  border-right: 1px dashed #ccc;
}
// zaikoreal 列ヘッダー背景色
#ZaikoRealTable.handsontable .zaikoreal_H {
  background: #606060 !important;
  color: white;
}
// zaikoreal 列ヘッダー 下線なし
#ZaikoRealTable.handsontable .zaikoreal_H_noBorder {
  background: #606060 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikoreal 列ヘッダー背景色(出荷)
#ZaikoRealTable.handsontable .zaikoreal_H_N {
  background: #386ba7 !important;
  color: white;
}
// zaikoreal 列ヘッダー 下線なし(出荷)
#ZaikoRealTable.handsontable .zaikoreal_H_N_noBorder {
  background: #386ba7 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikoreal 列ヘッダー背景色(在庫)
#ZaikoRealTable.handsontable .zaikoreal_H_Z {
  background: #339966 !important;
  color: white;
}
// zaikoreal 列ヘッダー 下線なし(在庫)
#ZaikoRealTable.handsontable .zaikoreal_H_Z_noBorder {
  background: #339966 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_H_group { //グループ行
  background: #D6DCE4 !important;
}
#ZaikoRealTable.handsontable .zaikoreal_H_total { //合計行
  background: #E1EBE5 !important;
}
#ZaikoRealTable.handsontable .zaikoreal_H_info { //明細行
  background: #F2F2F2;
}
#ZaikoRealTable.handsontable .zaikoreal_RH {
}
#ZaikoRealTable.handsontable .zaikoreal_RH_total {
  background: #D6E4DC;
}
#ZaikoRealTable.handsontable .zaikoreal_T {
  background: #C0504D;
  color: white;
}

// zaikoreal セル文字色
#ZaikoRealTable.handsontable .zaikoreal_cell_bold { //太字
  font-weight: bold;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_top_bold {
  border-top: 0.5px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_right_bold {
  border-right: 0.5px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_right_bold_white {
  border-right: 0.5px white solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_right_bold2 {
  border-right: 1.5px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_left_bold {
  border-left: 0.5px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_left_bold_white {
  border-left: 0.5px white solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_border_left_bold2 {
  border-left: 1.5px black solid !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_fg_gray {
  color: #BBB !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_fg_red {
  color: #F00 !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_fg_blue {
  color: #7491CD !important;
}
#ZaikoRealTable.handsontable .zaikoreal_cell_fg_none {
  color: transparent !important;
}
//99/99/99
#ZaikoRealTable.handsontable .zaikoreal_cell_bg_invalid {
  color: #808080 !important;
  background: #D9D9D9 !important;
}
//期限切れ
#ZaikoRealTable.handsontable .zaikoreal_cell_bg_over {
  color: #FFF !important;
  background: #FF4040 !important;
}
//2/3共用切れ
#ZaikoRealTable.handsontable .zaikoreal_cell_bg_over_2_3 {
  background: #FFC7CE !important;
}

//zaikoreal 選択時
#ZaikoRealTable.handsontable .currentRow, 
#ZaikoRealTable.handsontable .area,
#ZaikoRealTable.handsontable .current {
  background: #DDEBF7;
}

//zaikoreal 入力可能
#ZaikoRealTable.handsontable .zaikoreal_cell_editable {
  background: #FFFFCC;
}
//zaikoreal 入力可能 選択時
#ZaikoRealTable.handsontable .zaikoreal_cell_editable.currentRow, 
#ZaikoRealTable.handsontable .zaikoreal_cell_editable.area,
#ZaikoRealTable.handsontable .zaikoreal_cell_editable.current {
  background: #F6F2B3;
}
//zaikoreal 入力不可
#ZaikoRealTable.handsontable .zaikoreal_cell_uneditable {
  background: #E8E8E8;
}
//zaikoreal 入力不可 選択時
#ZaikoRealTable.handsontable .zaikoreal_cell_uneditable.currentRow, 
#ZaikoRealTable.handsontable .zaikoreal_cell_uneditable.area,
#ZaikoRealTable.handsontable .zaikoreal_cell_uneditable.current {
  background: #D7D3C7;
}
//zaikoreal 入力エラー
#ZaikoRealTable.handsontable .zaikoreal_cell_error {
  background: #FFCCCC;
}
//zaikoreal 警告
#ZaikoRealTable.handsontable .zaikoreal_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 編集済
#ZaikoRealTable.handsontable .zaikoreal_cell_edited {
  background: #A9D08E;
}
//zaikoreal 入力エラー 選択時
#ZaikoRealTable.handsontable .zaikoreal_cell_error.currentRow, 
#ZaikoRealTable.handsontable .zaikoreal_cell_error.area,
#ZaikoRealTable.handsontable .zaikoreal_cell_error.current {
  background: #F6BFB3;
}
//zaikoreal 警告 選択時
#ZaikoRealTable.handsontable .zaikoreal_cell_warn.currentRow, 
#ZaikoRealTable.handsontable .zaikoreal_cell_warn.area,
#ZaikoRealTable.handsontable .zaikoreal_cell_warn.current {
  background: #df9f29;
}
// 選択列ヘッダーの背景色
#ZaikoRealTable.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}


/////////////////////////////////////////
// zaikokigen 左上ヘッダーのした線を消す
#ZaikoKigenTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// zaikokigen 列ヘッダーの背景色デフォルト
#ZaikoKigenTable.handsontable thead th {
  background: #339966;
  color: white;
}
// zaikokigen 右側線はデフォルト破線する
#ZaikoKigenTable.handsontable th, 
#ZaikoKigenTable.handsontable td {
  border-right: 1px dashed #ccc;
}
// zaikokigen 列ヘッダー背景色
#ZaikoKigenTable.handsontable .zaikokigen_H {
  background: #606060 !important;
  color: white;
}
// zaikokigen 列ヘッダー 下線なし
#ZaikoKigenTable.handsontable .zaikokigen_H_noBorder {
  background: #606060 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikokigen 列ヘッダー背景色(出荷)
#ZaikoKigenTable.handsontable .zaikokigen_H_N {
  background: #386ba7 !important;
  color: white;
}
// zaikokigen 列ヘッダー 下線なし(出荷)
#ZaikoKigenTable.handsontable .zaikokigen_H_N_noBorder {
  background: #386ba7 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// zaikokigen 列ヘッダー背景色(在庫)
#ZaikoKigenTable.handsontable .zaikokigen_H_Z {
  background: #339966 !important;
  color: white;
}
// zaikokigen 列ヘッダー 下線なし(在庫)
#ZaikoKigenTable.handsontable .zaikokigen_H_Z_noBorder {
  background: #339966 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_H_group { //グループ行
  background: #D6DCE4 !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_H_total { //合計行
  background: #E1EBE5 !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_H_info { //明細行
  background: #F2F2F2;
}
#ZaikoKigenTable.handsontable .zaikokigen_RH {
}
#ZaikoKigenTable.handsontable .zaikokigen_RH_total {
  background: #D6E4DC;
}
#ZaikoKigenTable.handsontable .zaikokigen_T {
  background: #C0504D;
  color: white;
}

// zaikokigen セル文字色
#ZaikoKigenTable.handsontable .zaikokigen_cell_bold { //太字
  font-weight: bold;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_top_bold {
  border-top: 0.5px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_right_bold {
  border-right: 0.5px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_right_bold_white {
  border-right: 0.5px white solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_right_bold2 {
  border-right: 1.5px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_left_bold {
  border-left: 0.5px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_left_bold_white {
  border-left: 0.5px white solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_border_left_bold2 {
  border-left: 1.5px black solid !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_fg_gray {
  color: #BBB !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_fg_red {
  color: #F00 !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_fg_blue {
  color: #7491CD !important;
}
#ZaikoKigenTable.handsontable .zaikokigen_cell_fg_none {
  color: transparent !important;
}
//99/99/99
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_invalid {
  color: #808080 !important;
  background: #D9D9D9 !important;
}
//期限切れ
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_over {
  color: #FFF !important;
  background: #FF4040 !important;
}
//2/3許容切れ
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_over_2_3 {
  background: #FFC7CE !important;
}
//1/2許容切れ
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_over_1_2 {
  background: #FFC7CE !important;
}
//得意先許容切れ
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_over_Tokui {
  background: #FFC7CE !important;
}
//～101日
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_101 {
  color: #FFF !important;
  background: #FF4040 !important;
}
//100～31日
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_031 {
  background: #FFB0B0 !important;
}
//30～11日
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_011 {
  background: #FFE0E0 !important;
}
//9999999
#ZaikoKigenTable.handsontable .zaikokigen_cell_bg_999 {
  color: #808080 !important;
  background: #D9D9D9 !important;
}

//zaikokigen 選択時
#ZaikoKigenTable.handsontable .currentRow, 
#ZaikoKigenTable.handsontable .area,
#ZaikoKigenTable.handsontable .current {
  background: #DDEBF7;
}

//zaikokigen 入力可能
#ZaikoKigenTable.handsontable .zaikokigen_cell_editable {
  background: #FFFFCC;
}
//zaikokigen 入力可能 選択時
#ZaikoKigenTable.handsontable .zaikokigen_cell_editable.currentRow, 
#ZaikoKigenTable.handsontable .zaikokigen_cell_editable.area,
#ZaikoKigenTable.handsontable .zaikokigen_cell_editable.current {
  background: #F6F2B3;
}
//zaikokigen 入力不可
#ZaikoKigenTable.handsontable .zaikokigen_cell_uneditable {
  background: #E8E8E8;
}
//zaikokigen 入力不可 選択時
#ZaikoKigenTable.handsontable .zaikokigen_cell_uneditable.currentRow, 
#ZaikoKigenTable.handsontable .zaikokigen_cell_uneditable.area,
#ZaikoKigenTable.handsontable .zaikokigen_cell_uneditable.current {
  background: #D7D3C7;
}
//zaikokigen 入力エラー
#ZaikoKigenTable.handsontable .zaikokigen_cell_error {
  background: #FFCCCC;
}
//zaikokigen 警告
#ZaikoKigenTable.handsontable .zaikokigen_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 編集済
#ZaikoKigenTable.handsontable .zaikokigen_cell_edited {
  background: #A9D08E;
}
//zaikokigen 入力エラー 選択時
#ZaikoKigenTable.handsontable .zaikokigen_cell_error.currentRow, 
#ZaikoKigenTable.handsontable .zaikokigen_cell_error.area,
#ZaikoKigenTable.handsontable .zaikokigen_cell_error.current {
  background: #F6BFB3;
}
//zaikokigen 警告 選択時
#ZaikoKigenTable.handsontable .zaikokigen_cell_warn.currentRow, 
#ZaikoKigenTable.handsontable .zaikokigen_cell_warn.area,
#ZaikoKigenTable.handsontable .zaikokigen_cell_warn.current {
  background: #df9f29;
}
// 選択列ヘッダーの背景色
#ZaikoKigenTable.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}


/////////////////////////////////////////
// kaihai 左上ヘッダーのした線を消す
#KaihaiTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// kaihai 列ヘッダーの背景色デフォルト
#KaihaiTable.handsontable thead th {
  background: #339966;
  color: white;
}
// kaihai 右側線はデフォルト破線する
#KaihaiTable.handsontable th, 
#KaihaiTable.handsontable td {
  border-right: 1px dashed #ccc;
}
// kaihai 列ヘッダー背景色
#KaihaiTable.handsontable .kaihai_H {
  background: #606060 !important;
  color: white;
}
// kaihai 列ヘッダー 下線なし
#KaihaiTable.handsontable .kaihai_H_noBorder {
  background: #606060 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
#KaihaiTable.handsontable .kaihai_H_before {
  background: #609060 !important;
  color: white;
}
// kaihai 列ヘッダー 下線なし
#KaihaiTable.handsontable .kaihai_H_before_noBorder {
  background: #609060 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}

#KaihaiTable.handsontable .kaihai_H_after {
  background: #606090 !important;
  color: white;
}
// kaihai 列ヘッダー 下線なし
#KaihaiTable.handsontable .kaihai_H_after_noBorder {
  background: #606090 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}

// kaihai 列ヘッダー背景色(出荷)
#KaihaiTable.handsontable .kaihai_H_N {
  background: #386ba7 !important;
  color: white;
}
// kaihai 列ヘッダー 下線なし(出荷)
#KaihaiTable.handsontable .kaihai_H_N_noBorder {
  background: #386ba7 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
// kaihai 列ヘッダー背景色(在庫)
#KaihaiTable.handsontable .kaihai_H_Z {
  background: #339966 !important;
  color: white;
}
// kaihai 列ヘッダー 下線なし(在庫)
#KaihaiTable.handsontable .kaihai_H_Z_noBorder {
  background: #339966 !important;
  color: white;
  // border-bottom: none
  border-bottom: 1px transparent solid !important;
}
#KaihaiTable.handsontable .kaihai_H_group { //グループ行
  background: #D6DCE4 !important;
}
#KaihaiTable.handsontable .kaihai_H_total { //合計行
  background: #E1EBE5 !important;
}
#KaihaiTable.handsontable .kaihai_H_info { //明細行
  background: #F2F2F2;
}
#KaihaiTable.handsontable .kaihai_RH {
}
#KaihaiTable.handsontable .kaihai_RH_total {
  background: #D6E4DC;
}
#KaihaiTable.handsontable .kaihai_T {
  background: #C0504D;
  color: white;
}


// kaihai セル文字色
#KaihaiTable.handsontable .kaihai_cell_bold { //太字
  font-weight: bold;
}
#KaihaiTable.handsontable .kaihai_cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_bottom_dashed {
  border-bottom: 1px #ccc dashed !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_top_bold {
  border-top: 0.5px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_right_bold {
  border-right: 0.5px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_right_bold_white {
  border-right: 0.5px white solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_right_bold2 {
  border-right: 1.5px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_left_bold {
  border-left: 0.5px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_left_bold_white {
  border-left: 0.5px white solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_border_left_bold2 {
  border-left: 1.5px black solid !important;
}
#KaihaiTable.handsontable .kaihai_cell_fg_gray {
  color: #BBB !important;
}
#KaihaiTable.handsontable .kaihai_cell_fg_red {
  color: #F00 !important;
}
#KaihaiTable.handsontable .kaihai_cell_fg_blue {
  color: #7491CD !important;
}
#KaihaiTable.handsontable .kaihai_cell_fg_none {
  color: transparent !important;
}
#KaihaiTable.handsontable .kaihai_cell_bg_item {
  background: #F0F0F0 !important;
}

//kaihai 選択時
#KaihaiTable.handsontable .currentRow, 
#KaihaiTable.handsontable .area,
#KaihaiTable.handsontable .current {
  background: #DDEBF7;
}

//kaihai 入力可能
#KaihaiTable.handsontable .kaihai_cell_editable {
  background: #FFFFCC;
}
//kaihai 入力可能 選択時
#KaihaiTable.handsontable .kaihai_cell_editable.currentRow, 
#KaihaiTable.handsontable .kaihai_cell_editable.area,
#KaihaiTable.handsontable .kaihai_cell_editable.current {
  background: #F6F2B3;
}
//kaihai 入力不可
#KaihaiTable.handsontable .kaihai_cell_uneditable {
  background: #E8E8E8;
}
//kaihai 入力不可 選択時
#KaihaiTable.handsontable .kaihai_cell_uneditable.currentRow, 
#KaihaiTable.handsontable .kaihai_cell_uneditable.area,
#KaihaiTable.handsontable .kaihai_cell_uneditable.current {
  background: #D7D3C7;
}
//kaihai 成功
#KaihaiTable.handsontable .kaihai_cell_success {
  background: #CCFFCC;
}
//kaihai 情報
#KaihaiTable.handsontable .kaihai_cell_info {
  background: #CCCCFF;
}
//kaihai 入力エラー
#KaihaiTable.handsontable .kaihai_cell_error {
  background: #FFCCCC;
}
//kaihai 警告
#KaihaiTable.handsontable .kaihai_cell_warn {
  background: #FFB62F;
}
//tokubaimanage 編集済
#KaihaiTable.handsontable .kaihai_cell_edited {
  background: #A9D08E;
}
//kaihai 入力エラー 選択時
#KaihaiTable.handsontable .kaihai_cell_error.currentRow, 
#KaihaiTable.handsontable .kaihai_cell_error.area,
#KaihaiTable.handsontable .kaihai_cell_error.current {
  background: #F6BFB3;
}
//kaihai 警告 選択時
#KaihaiTable.handsontable .kaihai_cell_warn.currentRow, 
#KaihaiTable.handsontable .kaihai_cell_warn.area,
#KaihaiTable.handsontable .kaihai_cell_warn.current {
  background: #df9f29;
}
// 選択列ヘッダーの背景色
#KaihaiTable.handsontable .ht__highlight {
  color: #000 !important;
  background: #5B9BD5 !important;
}

#KaihaiDropZone .contents {
  margin: 5px;
  padding: 5px;
  border: 2px dashed #ccc;
  background-color: #eee;
  color: #888;
  max-width: 600px;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  &__input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    margin-top: 20px;
  }
}